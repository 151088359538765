.rc-time-picker-input {
  border: none !important;
  font-size: 30px !important;
  background-color: none !important;

  &:focus,
  &:focus-visible {
    outline: none !important;
    box-shadow: none !important;
  }
}
:focus-visible {
  outline: none !important;
}
