@import "../../Assets/Scss/variables.scss";

.inputWrapper {
  position: relative;

  .leftIconWrapper {
    top: 48%;
    left: 15px;
    cursor: pointer;
    position: absolute;
    transform: translateY(-50%);
  }
  .rightIconWrapper {
    top: 48%;
    right: 15px;
    cursor: pointer;
    position: absolute;
    transform: translateY(-50%);
  }
}
