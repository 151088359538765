@import url("../../Assets//Scss/globalStyleSheet.scss");
@import url("../../Assets//Scss/variables.scss");
.rdrDefinedRangesWrapper {
  display: none;
}
.rdrEndEdge,
.rdrStartEdge,
.rdrInRange {
  background-color: #ff7d26 !important;
  &:hover {
    border: 1px solid #ff7d26 !important;
  }
}
.rdrDayToday .rdrDayNumber span:after {
  content: "";
  background: #ff7d26 !important;
}
.rdrDateDisplayItemActive {
  border-color: #ff7d26 !important;
}
.rdrDateDisplayItem {
  &:hover {
    border-color: #ff7d26 !important;
  }
}
