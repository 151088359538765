@import "./variables.scss";

i,
a,
p,
b,
u,
ol,
ul,
li,
tr,
th,
td,
h1,
h2,
h3,
h4,
h5,
h6,
var,
div,
img,
nav,
span,
menu,
time,
html,
body,
form,
small,
audio,
table,
tbody,
tfoot,
thead,
legend,
center,
figure,
footer,
header,
iframe,
article,
caption,
section,
figcaption,
video {
  margin: 0;
  border: 0;
  padding: 0;
  vertical-align: baseline;
  font-family: "Nunito Sans", sans-serif;
}
a {
  text-decoration: none;
}
small {
  font-size: 6pt !important;
}
nav,
menu,
header,
figure,
footer,
details,
figcaption,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}
.divOverflowY {
  height: 94vh !important;
  overflow-y: scroll !important;
}
.fs-md-3 {
  font-size: 1.5rem !important;
}
blockquote,
q {
  quotes: none;
}

.bgBlueThisThat {
  background-image: url(../Images/bgThisThat.png);
}
.bgOrange {
  background-color: $orangeLight !important;
}
.bgOrangeLight {
  background-color: #ffb54dc3 !important;
}
.bgOrangeLightDim {
  background-color: #f8ba645e !important;
}
.bgLightGray {
  background-color: $lightGray !important;
}
.bgLayoutLightGray {
  background-color: $WhiteGray !important;
}
.orangeBorder {
  border: 1px solid $orangeLight !important;
}
.borderRadius-12 {
  border-radius: 12px;
}
.borderTopRightRadius {
  border-top-right-radius: 16px;
}
.borderTopLeftRadius {
  border-top-left-radius: 16px;
}
.borderBottomLeftRadius {
  border-bottom-left-radius: 16px;
}
.borderBottomRightRadius {
  border-bottom-right-radius: 16px;
}
.boxShadowOrange {
  box-shadow: 0 0 8px 2px $orangeLight;
}
.boxShadowDark {
  box-shadow: 2px 2px 4px rgba(0, 0, 2, 0.5);
}
.boxShadowBlue {
  box-shadow: 0px 0px 10px 4px rgba(0, 123, 255, 0.36);
  -moz-box-shadow: 0px 0px 10px 4px rgba(0, 123, 255, 0.36);
  -webkit-box-shadow: 0px 0px 10px 4px rgba(0, 123, 255, 0.36);
}
.errorField {
  color: $orangeLight;
  font-size: $font-12;
  margin-bottom: 0 !important;
}
.form-control-lg {
  padding: 0.6rem 0.6rem;
  font-size: 15px !important;
  border-radius: 12px !important;
  border: $lightGray solid 1px !important;

  &::placeholder {
    color: $darkGray;
  }
  &:focus {
    outline: none;
    border: $orangeLight solid 1px !important;
    box-shadow: 0.25rem 0.25rem 0.5rem rgba(250, 171, 0, 0.288) !important;
  }
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
.textOrange {
  color: $orangeLight !important;
}
.textBlue {
  color: $Blue !important;
}
.bgBlue {
  background-color: $darkBlue !important;
}
.textDarkBlue {
  color: $darkBlue !important;
}
.textGray {
  color: $darkGray !important;
}
table {
  border-spacing: 0;
  border-collapse: collapse;
}
.BgChecked {
  background-color: $orangeLight !important;
  border: 1px solid $orangeLight !important;
}
.cursorPointer {
  cursor: pointer !important;
}
.BgChecked,
input:focus {
  &:focus {
  }
}
.CardHeightFixed {
  height: 95vh;
  overflow-y: scroll !important ;
}

@mixin bgProperties {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat !important;
}
.bgProperties {
  @include bgProperties;
}
.HLine {
  width: 20%;
  height: 1px;
  padding: 0px;
  border: 1px solid $darkGray;
}
.modal {
  backdrop-filter: blur(3px);
  background-color: #00000076;
}
.editModel .modal-content {
  padding: 10px;
  border-radius: 32px;
  pointer-events: auto;
  border: none !important;
}

.slider-container {
  width: 90%;
  margin: auto;
  padding: 10px 0px;
  white-space: nowrap;
  height: 50% !important;
}

.slider-container .slick-slide {
  width: 50%;
  margin: 4px;
  border-radius: 4px;
}

.scrollingText {
  width: 100%;
  white-space: nowrap;
  display: inline-block;
  animation: scroll-left 30s linear infinite;
}

@keyframes scroll-left {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@media (max-width: 767.98px) {
  .fs-md-3 {
    font-size: 1.3rem !important;
  }
}

::-webkit-scrollbar {
  width: 5px;
  background-color: transparent !important;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: transparent !important;
}

::-webkit-scrollbar-track {
  background: transparent !important;
}

.user {
  width: 288px;
  height: 200px;
  border-width: 1px;
  border-style: solid;
  --un-border-opacity: 1;
  box-sizing: border-box;
  border-color: rgba(75, 85, 99, var(--un-border-opacity));
}

@mixin media-breakpoint-down($breakpoint) {
  @if $breakpoint == "xs" {
    @media (max-width: 575.98px) {
      @content;
    }
  } @else if $breakpoint == "sm" {
    @media (max-width: 767.98px) {
      .LeftNaveWidth {
        width: 100% !important;
      }
    }
  } @else if $breakpoint == "md" {
    @media (max-width: 991.98px) {
      .LeftNaveWidth {
        width: 80px !important;
      }
    }
  } @else if $breakpoint == "lg" {
    @media (max-width: 1199.98px) {
      .LeftNaveWidth {
        width: 90px !important;
      }
    }
  } @else if $breakpoint == "xl" {
    @media (max-width: 1399.98px) {
      .LeftNaveWidth {
        width: 90px !important;
      }
    }
  }
}
