@import "../../Assets/Scss/variables.scss";
@import "../../Assets//Scss/globalStyleSheet.scss";

.loadingOverlay {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 1051;
  position: fixed;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(5px);
  background: rgba(0, 0, 0, 0.5);
}

.loadingContent {
  text-align: center;
}

.spinner {
  color: $Blue;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
