@import "../../../Assets/Scss/globalStyleSheet.scss";

.childrenWidth {
  height: 96vh;
  // overflow-y: scroll;
  padding: 0px !important;
  @include bgProperties;
  background-image: url("../../../Assets/Images/createAccount/background.png");
}
.mainContainer {
  @include bgProperties;
  background-image: url("../../../Assets/Images/createAccount/background.png");
}

.rightSideBar {
  height: 98vh;
}

.leftSideNav {
  .LeftNaveWidth {
    width: 80px;
  }

  @media (max-width: 767.98px) {
    .LeftNaveWidth {
      width: 100%;
    }
  }

  @media screen and (max-width: 40em) {
    .LeftNaveWidth {
      width: 100%;
    }
  }
}

.ProfileClass {
  background-color: #ffe9db;
}

@media (max-width: 767px) {
  .childrenWidth {
    width: 100%;
    height: 100%;
    min-height: 98vh;
    // overflow-y: scroll;
    margin-bottom: 53px;
  }
}

@media screen and (max-width: 40em) {
  .childrenWidth {
    width: 100%;
    height: 100%;
    min-height: 100vh;
  }
}
