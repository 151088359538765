@import "../../Assets/Scss/globalStyleSheet.scss";

.fillBtn,
.OutlineBtn {
  position: relative;
  font-weight: bold !important;
  border-radius: 12px !important;
  border: 2px solid transparent !important;
}

.fillBtn {
  color: $darkBlack !important;
  background-color: $orangeLight !important;
  border: transparent 1px solid !important;
  &:hover {
    color: $darkBlack !important;
    background-color: $btnHoverOrange !important;
  }
}
.OutlineBtn {
  color: $orangeLight !important;
  border: 2px solid $orangeLight !important;
  box-shadow: 0px 4px 5px 2px rgba(0, 0, 0, 0.2);
  &:focus-visible,
  &:hover {
    color: $darkBlack !important;
    background-color: $orangeLight !important;
    .icon {
      color: $darkBlack !important;
    }
  }
}

.pressed {
  color: $white !important;
  border-color: $orangeLight !important;
  background-color: $orangeLight !important;
}
