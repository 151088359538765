@import "../../Assets/Scss/globalStyleSheet.scss";
.RightBar {
  background-color: #ffe9db !important;
  box-shadow: 0px 10px 10px -2px rgba(0, 0, 0, 0.1);

  .profileImage {

    width: 60px;
    height: 60px;
    border:2px solid $orangeLight;
  }
}

.modalPopup{
  margin-top: 38px;
  margin-right:-100px !important;
}