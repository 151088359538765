@import "../../Assets/Scss/globalStyleSheet.scss";
.mainMenu {
  background: $white;
  height: 100vh !important;
  margin-right: 50px !important;

  .ListGroup {
    .ListGroupItem {
      padding: 0;
      border: none;
      width: 100%;
      Button {
        width: 100%;
        border:none;
        padding: 10px;
        color: $orangeLight;
        display: flex;
        font-size: 24px;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        &:hover {
          color: $white;
          width: 100%;
          border-top-left-radius: 0px;
          border-bottom-left-radius: 0px;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
          background-color: $orangeLight;
        }
      }
    }
  }
  // box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
}

@media (max-width: 767px) {
  .mobileFooter {
    bottom: 0;
    width: 100%;
    z-index: 9999;
    overflow-x: scroll;
    background-color: $white;
    padding: 8px 8px 8px 8px;
    border-top: $Blue 4px solid;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    .ListGroupMobile {
      display: flex;
      flex-direction: row;
      justify-content: center;
      .ListGroupItem {
        padding: 0;
        border: none !important;
        border-radius: 100% !important;
        background-color: $white !important;

        Button {
          width: 40px;
          height: 40px;
          border: none;
          padding: 0px;
          display: flex;
          color: $orange;
          font-size: 25px;
          position: relative;
          align-items: center;
          justify-content: center;
          background-color: $white;
          border-radius: 100% !important;
        }

        Button::after {
          content: "";

          left: 50%;
          height: 0;
          width: 40px;
          bottom: -10px;
          z-index: 999999;
          position: absolute;
          transition: height 0.3s;
          background-color: $orange;
          transform: translateX(-50%);
        }

        Button:hover::after {
          height: 4px;
        }

        Button:hover {
          color: $white;
          background-color: $orange;
          border-radius: 100% !important;
          box-shadow: 0 0 10px rgba(255, 125, 38, 11);
        }
      }
    }
  }
}

@media screen and (max-width: 40em) {
  .mobileFooter {
    // padding: 10px 10px 10px 135px;
  }
}
