:root {
  --orange: #ff7d26;
}
$white: #fff !default;
$Blue: #001093 !default;
$pink: #ffb8a6 !default;
$orange: #ff7d26 !default;
$offWhite:#F3F3F3 !default;
$darkGray: #7a7a7a !default;
$darkBlue: #002244 !default;
$lightGray: #aaaaaa !default;
$WhiteGray: #eeeeee !default;
$darkBlack: #282828 !default;
$lightBlue: #25728e !default;
$orangeLight: #ff9500 !default;
$OrangeLight: #ffbb70 !default;
$btnHoverOrange: #ff914d !default;

$text-uppercase: uppercase !default;
$font-10: 10px !default;
$font-12: 12px !default;
$font-14: 14px !default;
$font-15: 15px !default;
$font-16: 16px !default;
$font-18: 18px !default;
$font-20: 20px !default;
$font-22: 22px !default;
$font-24: 24px !default;
$font-26: 26px !default;
$font-28: 28px !default;

$font-weight-300: 300 !default;
$font-weight-400: 400 !default;
$font-weight-500: 500 !default;
$font-weight-600: 600 !default;
$font-weight-700: 700 !default;
$font-weight-800: 800 !default;
$font-weight-800: 900 !default;

$border-radius-16: 16px !default;
